<template>
  <div class="c-app ag-login flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="4">
          <div class="h24-logo">
            <img src="/img/h24logo.png">
          </div>
          <CCardGroup>
            <CCard>
              <CCardBody class="position-relative">
                <CForm method="post" @submit.prevent="login">
                  <h2 class="text-center mb-3">Agent | Login</h2>
                  <CInput
                    class="input-login"
                    placeholder="Username"
                    autocomplete="username"
                    v-model="input.username"
                    required
                  >
                  </CInput>

                  <CInput
                    class="input-login"
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    v-model="input.password"
                    required
                  >
                  </CInput>
                  <CRow>
                    <CCol col="12" class="text-left">
                      <CButton type="submit" color="primary" class="px-4 btn-block btn-login" :disabled="isSubmit">{{isSubmit?'Loading...':'เข้าสู่ระบบ'}}</CButton>
                    </CCol>
                  </CRow>
                </CForm>

                <loading
                  :active="isLoading"
                  :can-cancel="false"
                  :is-full-page="false"
                  background-color="#EBEDEF"
                  :height="30"
                  :width="30"
                  color="#007bff"
                />
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import AuthService from '@/services/authService'
import Auth from '@/helpers/auth'
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'

export default {
  name: 'Login',
  data() {
    return {
      isSubmit: false,
      input: {
        username: '',
        password: ''
      },
      isLoading: false
    }
  },
  methods: {
    login(e) {

      this.isLoading = true
      AuthService.login(this.input)
      .then(async (response)=>{
        console.log('response', response)
        if(response.success) {
          await Auth.saveLogin(response.data)
          this.$router.push({name: 'Dashboard'})
        }else{
          throw new Error(response?.data?.message)
        }
      })
      .catch((e)=>{
        console.log(e)
        cAlert({
          ...e,
          title: 'ไม่สำเร็จ',
          text: e.message || 'Username หรือ Password ไม่ถูกต้อง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  },
  mounted() {
    console.log('mounted')
  }
}
</script>
<style lang="scss">
.ag-login {
  background-color: #000;
  background-image: url('/img/h24bg.png');
  background-repeat: no-repeat;
  background-size: cover;

  .h24-logo {
    img {
      max-width: 100%;
    }
  }
  .card {
    background-color: rgba(0, 0, 0, 0.8);
    border: solid 3px #F6D94D;
    border-radius: 20px;

    .card-body {
      h2 {
        margin-top: 10px;
        margin-bottom: 25px !important;
        color: #EEE;
      }

      .input-login {
        .form-control {
          border: 2px solid #F6D94D !important;
          border-radius: 15px !important;
          background-color: #000 !important;
          color: #FFF !important;
        }
      }

      .btn-login {
        background-color: #F6D94D;
        color: #000;
        border: 0;
        border-radius: 15px;
      }
    }
  }
}
</style>
